import React from 'react'
import {PageProps} from 'gatsby'
import {css} from '@emotion/react'

import useTOCHighlight from '../../hooks/useTOCHighlight'

import NavItem from './NavItem'
import {NavEntry} from './types'

const LINK_CLASS_NAME = 'toc-link'
const LINK_ACTIVE_CLASS_NAME = 'active'

const listCss = css`
  margin: 0 10px 4px;
  list-style-type: none;
  margin-left: 20px;
`

const linkCss = css`
  display: block;
  text-decoration: none;
  color: black;
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 4px;
  line-height: 16px;

  &.${LINK_ACTIVE_CLASS_NAME} {
    font-weight: bold;
  }

  &:hover {
    background: #e4e4ed;
  }
`

const navItemCss = css`
  margin: 4px 0;
`

const navCss = css`
  overflow-y: auto;
`

interface SidebarProps {
  className?: string
  scrollContainer?: React.RefObject<HTMLElement>
  navList?: NavEntry[]
  location: PageProps['location']
  pathPrefix?: string
}

export default function Sidebar({
  className,
  navList,
  location,
  pathPrefix = '',
  scrollContainer,
}: SidebarProps): JSX.Element {
  useTOCHighlight(scrollContainer, LINK_CLASS_NAME, LINK_ACTIVE_CLASS_NAME, 25)
  return (
    <nav css={navCss} className={className}>
      <ul css={listCss} className="parent-list">
        {navList?.length &&
          navList.map((entry) => (
            <NavItem
              location={location}
              key={entry.title}
              pathPrefix={pathPrefix}
              css={navItemCss}
              linkCss={linkCss}
              linkClassName={LINK_CLASS_NAME}
              listCss={listCss}
              navEntry={entry}
            />
          ))}
      </ul>
    </nav>
  )
}
