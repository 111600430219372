import React from 'react'
import {css} from '@emotion/react'

import {MultiSearchIndexResult} from '../hooks/useMultiSearchIndex'

interface SearchResultProps {
  active?: boolean
  result: MultiSearchIndexResult
  pathPrefix?: string
  onSelect: (result: MultiSearchIndexResult) => void
  onMouseEnter: (event: React.MouseEvent<HTMLDivElement>) => void
}

const searchResultCss = css`
  padding: 8px 10px;
  margin: 0;
  border-radius: 2px;
  cursor: pointer;

  > p {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const activeSearchResultCss = css`
  background: rgba(144, 219, 236, 0.3);
`

const titleCss = css`
  font-weight: bold;
`

function getResultPath(title: string, path: string[]): string {
  let resultPath = [...path, title]
  // if page title and first path heading are the same remove one
  if (resultPath[0] === resultPath[1]) resultPath = resultPath.splice(1).filter((item) => item)
  return resultPath.join(' » ')
}

export default function SearchResult({
  active,
  result,
  onSelect,
  onMouseEnter,
}: SearchResultProps): JSX.Element {
  const {ref, store} = result
  const {title, path = []} = store

  return (
    <li key={ref}>
      <div
        data-testid="search-result"
        css={[searchResultCss, active && activeSearchResultCss]}
        onClick={(event) => {
          event.stopPropagation()
          onSelect(result)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') onSelect(result)
        }}
        onMouseEnter={onMouseEnter}
      >
        <p css={titleCss}>{title}</p>
        <p>{getResultPath(title, path)}</p>
      </div>
    </li>
  )
}
