import {css, SerializedStyles} from '@emotion/react'
import {PageProps, withPrefix} from 'gatsby'
import React, {useContext, useState, useEffect, useMemo, useRef} from 'react'
import {throttle} from 'lodash'

import HorizontalNav from '../components/nav/HorizontalNav'
import Sidebar from '../components/nav/Sidebar'
import SearchBar from '../components/SearchBar'
import NavigationContext from '../providers/NavigationContext'
import Header from '../components/Header'
import Meta from '../components/Meta'
import VersionContext from '../providers/VersionContext'
import {PageEntry} from '../types'
import {secondaryHeaderHeight, headerHeight, fadingHeaderSpeed} from '../constants'
import ButtonLink from '../components/ButtonLink'
import UserContext from '../providers/UserContext'
import HeaderHeightContext from '../providers/HeaderHeightContext'
import normalizePath from '../utils/normalizePath'
import useDelay from '../hooks/useDelay'

import Base from './Base'
import {fixedHeaderCss, mainContentCss} from './PageWithHeader'

const wrapperCss = css`
  display: flex;
  flex: 1 1 auto;
  width: 1250px;
  max-width: 100vw;
  transition: margin-top ${fadingHeaderSpeed};
  margin: ${headerHeight + secondaryHeaderHeight}px auto 0 auto;
`

const sidebarContainer = css`
  width: 250px;
  padding: 0;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`

const initialSidebarOffset = headerHeight + secondaryHeaderHeight + 10
const sidebarCss = css`
  position: sticky;
  transition: top ${fadingHeaderSpeed};
  top: ${initialSidebarOffset}px;
  max-height: calc(100vh - ${initialSidebarOffset}px);
`

const secondaryHeaderCss = css`
  display: flex;
  background: #292f31;
  position: relative;
  flex: 1 1 100%;
  height: ${secondaryHeaderHeight}px;
  z-index: -1;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 5px 14px;
  border-bottom: 1px solid black;
  transition: all ${fadingHeaderSpeed};

  @media screen and (max-width: 1024px) {
    padding-right: 0px;
  }
`

const hiddenHeaderCss = css`
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
`

const buttonCss = css`
  max-width: 100px;
  margin: 0 10px 0 auto;
  padding: 6px 10px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

interface PageWithNavProps {
  className?: string
  children: React.ReactNode
  location: PageProps['location']
  pathPrefix?: string
  pageConfig?: PageEntry
  mainCssOverrides?: SerializedStyles
}

export default function PageWithNav({
  className,
  children,
  location,
  pageConfig,
  mainCssOverrides,
}: PageWithNavProps): JSX.Element {
  const {version} = useContext(VersionContext)
  const {fullHeaderHeight, setFullHeaderHeight} = useContext(HeaderHeightContext)
  const {navList, tableOfContents} = useContext(NavigationContext)
  const pathPrefix = version?.latest ? '' : `/${version?.identifier}`
  const mainRef = useRef<HTMLElement>(null)
  const {user, _state: userState} = useContext(UserContext)
  const demoPage = navList.find(({type}) => type === 'demo')
  const indices = useMemo(() => {
    if (!version) return []
    return [withPrefix(`${version.identifier}_search_index.json?v=${BUILD_DATE}`)]
  }, [version])
  const sidebarOffset = fullHeaderHeight + 10

  const [secondaryHeaderVisible, setSecondaryHeaderVisible] = useState(true)

  // reset the header when navigating between pages, otherwise can get stuck hidden if navigating
  // to a page without scroll overflow
  const [headerHideDelay, setHeaderHideDelay] = useDelay(1000)
  useEffect(() => {
    setSecondaryHeaderVisible(true)
    setHeaderHideDelay(false)
  }, [location.pathname, setHeaderHideDelay])

  useEffect(() => {
    let lastScrollY = window.scrollY
    const onScroll = throttle((): void => {
      if (headerHideDelay) setSecondaryHeaderVisible(lastScrollY > window.scrollY)
      lastScrollY = window.scrollY
    }, 300)

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [headerHideDelay])

  useEffect(() => {
    setFullHeaderHeight(
      secondaryHeaderVisible ? headerHeight + secondaryHeaderHeight : headerHeight
    )
  }, [secondaryHeaderVisible, setFullHeaderHeight])

  return (
    <>
      <Meta pageConfig={pageConfig} />
      <Base>
        <header css={fixedHeaderCss}>
          <Header pageConfig={pageConfig} location={location} />
          <div
            data-testid="secondary-nav"
            css={[secondaryHeaderCss, !secondaryHeaderVisible && hiddenHeaderCss]}
          >
            <HorizontalNav
              location={location}
              navList={navList}
              pathPrefix={pathPrefix}
              activeItem={pageConfig?.url}
              activeSection={pageConfig?.section}
            />
            {demoPage && userState === 'known' && !user?.email && (
              <ButtonLink css={buttonCss} to={normalizePath(demoPage?.url)}>
                Try for Free
              </ButtonLink>
            )}
            <SearchBar
              indices={indices}
              pathPrefix={version && !version.latest ? `/${version.identifier}` : ''}
            />
          </div>
        </header>

        <div
          css={[
            wrapperCss,
            css`
              margin-top: fullHeaderHeight;
            `,
          ]}
          className={className}
        >
          {!!(
            tableOfContents &&
            (tableOfContents.length > 1 || tableOfContents[0]?.items?.length)
          ) && (
            <aside css={sidebarContainer}>
              <Sidebar
                css={[
                  sidebarCss,
                  css`
                    top: ${sidebarOffset}px;
                    max-height: calc(100vh - ${sidebarOffset}px);
                  `,
                ]}
                location={location}
                navList={tableOfContents}
                pathPrefix={pathPrefix}
              />
            </aside>
          )}
          <main ref={mainRef} css={[mainContentCss, mainCssOverrides]}>
            {children}
          </main>
        </div>
      </Base>
    </>
  )
}
