import React from 'react'

import Link from '../Link'

import getNavItemUrl from './getNavItemUrl'
import {NavItemProps} from './types'

export default function NavItem({
  navEntry,
  location,
  pathPrefix = '',
  flat,
  className,
  linkCss,
  listCss,
  linkClassName = '',
}: NavItemProps): JSX.Element {
  const {title, url = '', items} = navEntry

  return (
    <li className={className}>
      {url ? (
        <Link css={linkCss} className={linkClassName} to={getNavItemUrl(navEntry, pathPrefix)}>
          {title}
        </Link>
      ) : (
        // TODO: style header
        title
      )}
      {!!items?.length && !flat && (
        <ul css={listCss}>
          {items.map((entry) => (
            <NavItem
              key={`${entry.title}:${entry.url}`}
              location={location}
              pathPrefix={pathPrefix}
              className={className}
              linkCss={linkCss}
              listCss={listCss}
              linkClassName={linkClassName}
              navEntry={entry}
            />
          ))}
        </ul>
      )}
    </li>
  )
}
