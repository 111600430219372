import React, {useContext, useMemo} from 'react'

import {NavEntry} from '../components/nav/types'

import NavigationContext from './NavigationContext'
import UserContext from './UserContext'

interface NavigationProviderProps {
  navList?: NavEntry[]
  tableOfContents?: NavEntry[]
}

export default function NavigationProvide({
  children,
  navList,
  tableOfContents,
}: React.PropsWithChildren<NavigationProviderProps>): JSX.Element {
  const {user} = useContext(UserContext)
  const visibleNavList = useMemo(
    () =>
      navList?.filter((navEntry) => {
        // hide links with authDisplay mode based on auth state
        if (navEntry.authDisplay === 'unauthenticated' && user?.email) return false
        if (navEntry.authDisplay === 'authenticated' && !user?.email) return false
        return true
      }),
    [navList, user]
  )
  const navigationContext = useMemo(
    () => ({
      navList: visibleNavList,
      tableOfContents,
    }),
    [visibleNavList, tableOfContents]
  )
  return (
    <NavigationContext.Provider value={navigationContext}>{children}</NavigationContext.Provider>
  )
}
