import React, {useEffect, useState} from 'react'

export default function useDelay(
  delay: number
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [delayReached, setDelayReached] = useState(false)

  useEffect(() => {
    let timeoutId: number

    if (!delayReached) {
      timeoutId = window.setTimeout(() => {
        setDelayReached(true)
      }, delay)
    }

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [delayReached, delay])

  return [delayReached, setDelayReached]
}
