import React, {forwardRef} from 'react'
import {css} from '@emotion/react'

import Link, {LinkProps} from './Link'

const buttonCss = css`
  font-size: 16px;
  text-align: center;
  transition: all 300ms;
  background-color: #139ebf;
  padding: 10px 15px;
  border: none;
  color: white;

  &:hover {
    background-color: #1086a3;
  }

  svg {
    vertical-align: middle;
  }
`

interface ButtonLinkProps extends LinkProps {
  icon?: React.ReactNode
}

function ButtonLink(
  {children, icon, ...passthru}: ButtonLinkProps,
  ref: React.Ref<HTMLAnchorElement>
): JSX.Element {
  return (
    /* @ts-ignore */
    <Link ref={ref} {...passthru} css={buttonCss}>
      {children}
      {icon}
    </Link>
  )
}

export default forwardRef(ButtonLink)
