import {useCallback, useState} from 'react'
import lunr from 'lunr'

type LoadSearchIndex = () => Promise<void>

export type Indices = (string | SerializedIndexWithStore)[]

interface SerializedIndexWithStore {
  index: Record<string, unknown>
  store: Record<string, unknown>
}

interface IndexWithStore {
  index: lunr.Index
  store: Record<string, unknown>
}

interface MultiSearchIndexState {
  multiSearchIndex?: MultiSearchIndex
  loadSearchIndex: LoadSearchIndex
  loading: boolean
  loaded: boolean
  error?: string
}

interface StoredSearchResult {
  title: string
  url: string
  path: string[]
}

export interface MultiSearchIndexResult extends lunr.Index.Result {
  store: StoredSearchResult
}

class MultiSearchIndex {
  indices: IndexWithStore[]

  constructor(indices: IndexWithStore[]) {
    this.indices = indices
  }

  search(queryString: string): MultiSearchIndexResult[] {
    return this.indices.reduce<MultiSearchIndexResult[]>((acc, searchIndex) => {
      try {
        // run the query on every search index and concat the results together sorted by score
        return acc
          .concat(
            searchIndex.index.search(queryString).map((result) => ({
              // zip stored value with lunr result
              store: searchIndex.store[result.ref],
              ...result,
            }))
          )
          .sort((a, b) => b.score - a.score)
      } catch (e) {
        return acc
      }
    }, [])
  }
}

export default function useMultiSearchIndex({
  indices,
  loadOnMount = false,
}: {
  indices: Indices
  loadOnMount?: boolean
}): MultiSearchIndexState {
  const [loading, setLoading] = useState(!!loadOnMount)
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [multiSearchIndex, setMultiSearchIndex] = useState<MultiSearchIndex | null>(null)

  const loadSearchIndex = useCallback(() => {
    setMultiSearchIndex(null)
    setLoading(true)
    setLoaded(false)
    setError(null)

    return Promise.all(
      indices.map((pathOrIndex) => {
        if (typeof pathOrIndex === 'string') {
          return fetch(pathOrIndex).then((response) => response.json())
        }
        return Promise.resolve(pathOrIndex as SerializedIndexWithStore)
      })
    )
      .then((indexResults) =>
        indexResults.map(({index, store}) => ({index: lunr.Index.load(index), store}))
      )
      .then((searchIndices: IndexWithStore[]) => {
        setMultiSearchIndex(new MultiSearchIndex(searchIndices))
        setLoading(false)
        setLoaded(true)
        setError(null)
      })
      .catch(() => {
        setLoading(false)
        setError('Failed to load search index')
      })
  }, [indices])

  const multiSearchIndexState: MultiSearchIndexState = {
    loadSearchIndex,
    loading,
    loaded,
  }
  if (multiSearchIndex) multiSearchIndexState.multiSearchIndex = multiSearchIndex
  if (error) multiSearchIndexState.error = error
  return multiSearchIndexState
}
