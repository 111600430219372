import React, {useMemo} from 'react'

import {Version} from '../types'

import VersionContext from './VersionContext'

interface VersionProviderProps {
  versions: Version[]
  version?: Version
}
export default function VersionProvider({
  children,
  version,
  versions,
}: React.PropsWithChildren<VersionProviderProps>): JSX.Element {
  const versionsContext = useMemo(
    () => ({
      versions,
      version,
    }),
    [version, versions]
  )
  return <VersionContext.Provider value={versionsContext}>{children}</VersionContext.Provider>
}
