import {createContext} from 'react'

import {NavEntry} from '../components/nav/types'

interface NavigationContext {
  navList: NavEntry[]
  tableOfContents: NavEntry[]
}

export default createContext<NavigationContext>({navList: [], tableOfContents: []})
